import React, { useState, useEffect } from 'react'
import "./imageTable.scss"
import axios from "axios"
import DataTable from 'react-data-table-component'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { Link, useParams, useNavigate } from "react-router-dom"
import ConfirmationModal from '../Modal/ConfirmationModal'
import { toast, ToastContainer, cssTransition } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { saveAs } from 'file-saver'
import { CSVLink } from 'react-csv'
import { RegExp } from 'core-js'
import { has } from 'core-js/core/dict'

const ImageTable = () => {
    const navigate = useNavigate()
    const [images, setImages] = useState<any[]>([])
    const [search, setSearch] = useState("")
    const [filteredImages, setFilteredImages] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [filterValue, setFilterValue] = useState('All')
    const [selectedData, setSelectedData] = useState<any[]>([])
    const [toggleCleared, setToggleCleared] = useState(false)
    const [selectedDate, setSelectedDate] = useState(null)
    const [fetchAPI, setFetchAPI] = useState(false)
    const [apiData, setAPIData] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [modalText, setModalText] = useState('')
    const [modalAction, setModalAction] = useState('')
    const [modalHeader, setModalHeader] = useState('')
    const [showInput, setShowInput] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState('All')
    const [deleting, setDeleting] = useState(false)
    const [assignUser, setAssignUser] = useState(false)
    const [getUser, setGetUser] = useState([])
    const [userDetails, setUserDetails] = useState('')
    const [csvData, setCSVData] = useState([])
    const [isAdminLogin, setIsAdminLogin] = useState(false)
    const [selectedForExport, setSelectedForExport] = useState<any[]>([]) //Export those data which are only selected
    const [isZipData, setIsZipData] = useState(false)
    const [zipApiData, setZipApiData] = useState({
        "alt_text": "",
        "asset_id": "",
        "assigned_to": "",
        "created_on": "",
        "file_name": "",
        "image_id": '',
        "parent_id": "",
        "status": "",
        "thumbnail_file_path": ""
    })
    const [parentFile, setParentFile] = useState({
        "parent_file_name": ""
    })
    const [resetSelection, setResetSelection] = useState(false)
    const customId = "toast-id-dashboard"
    const assignId = "toast-id-assign"
    let zipAssetId = ''
    const { parentId } = useParams()
    const apiBasePath = process.env.REACT_APP_API_URL
    const basePath = process.env.REACT_APP_BASE_PATH
    const errorNotificaion = (errorMessage: string) => {
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_CENTER,
            // eslint-disable-next-line
            icon: false,
            toastId: customId,
            containerId: "Error"
        })
    }
    const Enter = cssTransition({
        enter: "fade-in",
        exit: "fade-out",
        appendPosition: false,
        collapse: true,
        collapseDuration: 300
    })

    const successAssignNotification = (successMessage: string) => {
        toast.success(successMessage, {
            position: toast.POSITION.TOP_CENTER,
            // eslint-disable-next-line
            icon: false,
            toastId: assignId,
            transition: Enter,
            containerId: "Success"
        })
    }

    const tableCustomStyles = {
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'medium',
                color: '#151515',
                paddingLeft: '0 8px',
                justifyContent: 'center',
                backgroundColor: '#F0F0F7'
            }
        }
    }

    const formatDataForCSV = (data: any) => {
        const formattedData = data.map((item: any) => ({
            Image: item.thumbnail_file_path,
            FileName: item.file_name,
            AltText: item.alt_text,
            DateAdded: item.created_on,
            Status: getStatusText(item.status),
        }))
        return formattedData
    }

    const getStatusText = (status: any) => {
        switch (status) {
            case 'I':
                return 'In progress'
            case 'D':
                return 'Draft'
            case 'R':
                return 'Reviewed'
            case 'P':
                return 'Pending'
            case 'F':
                return 'Failed'
            default:
                return ''
        }
    }

    const getImages = async () => {
        const userToken = localStorage.getItem("user-token")
        const clientId = localStorage.getItem("client_id")
        var formData = new FormData()
        if (userToken !== null && clientId !== null)
            formData.append("client_id", clientId)

        axios
            .post(`${apiBasePath}api/getImageData`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }
            )
            .then((response) => {
                const data = response.data.data
                if (response.status === 200) {
                    if (data !== '') {
                        setFetchAPI(true)
                        setAPIData(data)
                        const formattedData = formatDataForCSV(data)
                        setCSVData(formattedData)
                        if (isZipData === true) {
                            setIsZipData(false)
                        }
                    } else {
                        setFetchAPI(false)
                    }
                }
            })
            .catch((error) => {
                setFetchAPI(true)
            })
    }

    const filterImages = (imagesData: any, filter: any, date: Date | null) => {
        let filtered = imagesData

        if (filter !== 'All') {
            setSelectedForExport([])
            filtered = filtered.filter((image: { status: any }) => image.status === filter)
        }

        if (date) {
            toast.dismiss()
            setSelectedForExport([])
            const selectedDate = new Date(date.setHours(0, 0, 0, 0))
            filtered = filtered.filter((image: { created_on: any }) => {
                const imageDate = new Date(image.created_on)
                const imageDateWithoutTime = new Date(imageDate.setHours(0, 0, 0, 0))
                return imageDateWithoutTime.getTime() === selectedDate.getTime()
            })
        }
        setFilteredImages(filtered)
    }

    const columns = [
        {
            name: '',
            cell: (row: any) => {
                if (isAdminLogin && row.assigned_to) {
                    return <i data-tag="allowRowEvents" className="personAssignIcon" title={`Assigned to: ${row.assigned_to}`}></i>;
                } else {
                    return null;
                }
            },
            width: '10px'
        },
        {
            name: 'Image',
            cell: (row: any) => {
                if (row.file_name.includes('.zip')) {
                    return <img className='zip-icon' src={require('../../assets/images/zip-icon.png')} width={50} alt={row.thumbnail_file_path}></img>;
                } else if (row.file_name.includes('.epub')) {
                    return <img className='epub-icon' src={require('../../assets/images/epub-icon.png')} width={50} alt={row.thumbnail_file_path}></img>;
                } else {
                    return <img className='thumbnail-image' src={row.thumbnail_file_path} width={50} alt={row.thumbnail_file_path}></img>;
                }
            },
            selector: (row: any) => row.thumbnail_file_path,
            width: '100px',
            headerClass: 'custom-column'
        },
        {
            name: 'File Name',
            cell: (row: any) => <span data-tag="allowRowEvents" className='column-alt-text'>{row.file_name}</span>,
            width: '250px'
        },
        // {
        //     name: 'Alt Text',
        //     cell: (row: any) => {
        //         if (row.alt_text) {
        //             return <span data-tag="allowRowEvents" className='column-alt-text'>{row.alt_text}</span>;
        //         }
        //         return null;
        //     },
        //     selector: (row: any) => row.alt_text,
        //     width: '350px'
        // },
        {
            name: 'Date Added',
            selector: (row: any) => row.created_on,
            sortable: true
        },
        {
            name: 'Status',
            cell: (row: any) => {
                if (row.status) {
                    return (
                        <div className={`fileStatus ${getStatusClassName(row.status)}`}>
                            {getStatusText(row.status)}
                        </div>
                    );
                }
                return null;
            },
            sortable: true,
            sortFunction: (a: any, b: any) => {
                const statusA = getStatusText(a.status)
                const statusB = getStatusText(b.status)
                return statusA.localeCompare(statusB)
            }
        }
    ]

    const getStatusClassName = (status: any) => {
        switch (status) {
            case 'I':
                return 'progress-btn';
            case 'D':
                return 'draft-btn';
            case 'R':
                return 'reviewed-btn';
            case 'P':
                return 'pending-btn';
            case 'F':
                return 'failed-btn';
            default:
                return '';
        }
    };

    useEffect(() => {
        isAdmin()
    }, [])

    const isAdmin = async () => {
        const userToken = localStorage.getItem("user-token")
        if (userToken !== null)
            axios
                .get(
                    `${apiBasePath}api/admin/checkUsersRoleData`,
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`
                        }
                    }
                )
                .then((response) => {
                    console.log(response.data)


                    const data = response.data.data
                    if (response.status === 200) {
                        if (data.usertype === 'Admin' || data.usertype === 'Super admin') {
                            setIsAdminLogin(true)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
    }

    useEffect(() => {
        setImages(apiData)
        setFilteredImages(apiData)
        filterImages(apiData, filterValue, selectedDate)
        console.log(apiData, '---');

    }, [apiData])

    function escapeRegExp(string: any) { // The RegExp escapes special characters in a given string
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    }

    useEffect(() => {
        const escapedSearch: any = escapeRegExp(search)
        const result: any = images.filter(image => {
            if (image.alt_text !== null) {
                toast.dismiss()
                const fileNameMatch = image.file_name.toLowerCase().match(escapedSearch.toLowerCase())
                // const altTextMatch = image.alt_text.toLowerCase().match(escapedSearch.toLowerCase())
                return fileNameMatch
            }
        })
        // setFilteredImages(result)
        filterImages(result, filterValue, selectedDate)
    }, [search])

    useEffect(() => {
        filterImages(images, filterValue, selectedDate)
    }, [filterValue, selectedDate])

    const handleRowSelected = (state: any) => {
        toast.dismiss()
        if (!resetSelection) {
            setSelectedData(state.selectedRows);
        }
        setSelectedForExport(state.selectedRows)
    }

    const handleStatusFilter = (selectedFilter: string) => {
        toast.dismiss()
        setSelectedStatus(selectedFilter)
        setFilterValue(selectedFilter)
        filterImages(images, selectedFilter, selectedDate)
    }

    const handleDelete = () => {
        if (selectedData.length > 0) {
            const fileName = selectedData[0].file_name
            setModalAction('Delete')
            setModalHeader(`<i class="deleteConfIcon"></i><span class="delete-header">Delete File</span>`)
            setModalText(
                `<span class="deleteText">Are you sure you want to delete ${selectedData.length > 1 ? `these <span class="delete-file-name">${selectedData.length}</span> files` :
                    `<span class="delete-file-name">${fileName}</span>`
                } ? This action cannot be undone.</span>`
            )
            setShowInput(false)
            setShowModal(true)
            setTimeout(() => {
                const cancelButton = document.querySelector('.cancel-button') as HTMLButtonElement | null
                if (cancelButton) {
                    cancelButton.focus() // Autofocus the Cancel button when the Delete button is clicked
                }
            }, 0)
        } else {
            toast.dismiss()
            errorNotificaion("Please select an image to delete.")
        }
    }

    const handleAssign = () => {
        if (selectedData.length > 0) {
            setModalAction('Assign')
            setModalHeader(`<span class="assign-header">Assign</span>`)
            setModalText('Assign to')
            setShowInput(true)
            setShowModal(true)
        } else {
            toast.dismiss()
            errorNotificaion("Please select an image to assign.")
        }
    }

    const handleModalConfirm = async () => {
        if (modalAction === 'Delete') {
            setDeleting(true)

            const imageIds = selectedData.map((data) => data.image_id)
            const requestData = { image_id: imageIds }

            axios
                .post(
                    `${apiBasePath}api/deleteImages`,
                    requestData,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('user-token')}`,
                        },
                    }
                )
                .then((response) => {
                    // Handle the response if needed
                    setDeleting(false)
                    setShowModal(false)
                    if (selectedData.length === csvData.length) { // for deleting all the data in dashboard, No files to show page displays
                        setFetchAPI(true)
                        setImages([])
                    }
                    getImages()
                    setToggleCleared(true) // set the selected rows state to true
                    setResetSelection(true) // sets the resetSelection flag to true
                    successAssignNotification("Image(s) deleted successfully")
                })
                .catch((error) => {
                    // Handle the error if needed
                    setDeleting(false)
                })
        } else if (modalAction === 'Assign') {
            setAssignUser(true)
            const emailIdInput = document.querySelector(".assignModalBody") as HTMLInputElement | null
            const emailId = emailIdInput?.getElementsByTagName('input')[0].value

            const assignedUsers = getUser // Replace with the actual assigned users
            const imageIds = selectedData.map((data) => data.image_id)
            const matchedUserDetail: any = assignedUsers.find((userDetail: any) => userDetail.email === emailId)
            const requestData = {
                assigned_users: matchedUserDetail.id,
                image_id: imageIds,
            }


            if (matchedUserDetail) {
                try {
                    const response = await axios.post(
                        `${apiBasePath}api/assignImages`,
                        requestData,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('user-token')}`,
                            },
                        }
                    )

                    // Reset state and close the modal
                    setAssignUser(false)
                    setShowModal(false)
                    getImages()
                    setToggleCleared(true) // set the selected rows state to true
                    setResetSelection(true) // sets the resetSelection flag to true
                    successAssignNotification("Image(s) assigned successfully")
                } catch (error) {
                    console.log(error) // Handle the error if needed
                }
            }
        }
    }

    useEffect(() => {
        // When the resetSelection flag is true, reset the selectedData state to an empty array
        if (resetSelection) {
            setSelectedData([])
            setResetSelection(false) // Reset the resetSelection flag back to false
        }
        // Check if rows have been cleared (deselected)
        if (toggleCleared) {
            setSelectedData([]) // Clear the selected rows state
            setToggleCleared(false) // Reset the toggleCleared state back to false
        }
    }, [resetSelection])

    const handleModalCancel = () => {
        setShowModal(false)
    }

    useEffect(() => {
        if (parentId) {
            setIsZipData(true)
            getZipImages(parentId)
        } else {
            getImages()
        }
    }, [parentId])

    const handleRowClicked = (row: any) => {
        toast.dismiss()
        const updatedData = images.map(item => {
            if (row.image_id === item.image_id && row.file_name.includes('.zip') && 
            (row.status !== 'P' && row.status !== 'I' && row.status !== 'F')) {
                navigate(`../${basePath}/Dashboard/${row.asset_id}`)
                setZipApiData(row)
                setIsZipData(true)
                zipAssetId = row.asset_id
                getZipImages(zipAssetId)
                // console.log("ZIP FILE")
            } else if (row.image_id === item.image_id && (row.status !== 'P' && row.status !== 'I' && row.status !== 'F')) {
                navigate(`../${basePath}/altTextDetails/${row.asset_id}`)
            }
        })
    }

    const getZipImages = async (parentId: any) => {
        const userToken = localStorage.getItem("user-token")
        const clientId = localStorage.getItem("client_id")
        var formData = new FormData()
        if (userToken !== null && clientId !== null && parentId !== null)
            formData.append("client_id", clientId)
        formData.append("asset_id", parentId)
        axios
            .post(`${apiBasePath}api/getImageData`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }
            )
            .then((response) => {
                console.log(response.data.data[0].asset_id)


                const data = response.data.data
                const responseData = response.data
                console.log(data, "ZIP DATA")
                if (response.status === 200) {
                    setImages(data)
                    setFilteredImages(data)
                    setSelectedData([])
                    setFetchAPI(true)
                    setAPIData(data)
                    setIsZipData(true)
                    setParentFile(responseData)
                    const formattedData = formatDataForCSV(data)
                    setCSVData(formattedData)
                }
            })
            .catch((error) => {
                // setFetchAPI(true)
                console.log(error)
            })
    }

    const upload = () => {
        navigate(`../${basePath}/Upload`)
    }

    const getUsers = () => {
        const userToken = localStorage.getItem("user-token")
        if (userToken !== null)
            axios
                .get(
                    `${apiBasePath}api/usersList`,
                    {
                        headers: {
                            Authorization: `Bearer ${userToken}`
                        }
                    }
                )
                .then((response) => {

                    const data = response.data.data
                    const userDetails = response.data.data.map((user: any) => {
                        return user.email
                    })

                    if (response.status === 200) {
                        if (data !== '') {
                            setGetUser(data)
                            setUserDetails(userDetails)
                        }
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
    }

    useEffect(() => {
        getUsers()
    }, [])

    const exportCSV = () => {
        toast.dismiss()
        setCSVData([])
        if (selectedForExport.length === 0) {
            errorNotificaion("Please select a file to export.")
        }
    }
    const handleChangeRaw = (date: any, event: any) => {
        if (date.type !== "click") {
            const inputValue = date.currentTarget.value
            const sanitizedValue = inputValue.replace(/[^0-9/]/g, "").substr(0, 10)
            date.currentTarget.value = sanitizedValue
        }
    }

    const handleRefresh = () => { // handles component refresh based on isZipData true or false
        toast.dismiss()
        setToggleCleared(true) // set the selected rows state to true
        setResetSelection(true) // sets the resetSelection flag to true
        if (isZipData) {
            getZipImages(parentId)
        } else {
            getImages()
        }
    }

    // console.log(selectedData, selectedData.length, "selectedData")
    // console.log(csvData, csvData.length, "CSVDATA")
    // console.log(zipApiData, "zipApiData")
    return (
        <div className="dashboardPage container mt-4">
            {isZipData ? (
                <>
                    <Link
                        type="button"
                        className="btn btn-primary dashboardPath mb-1 me-1"
                        to={`${basePath}/Dashboard`}
                        onClick={() => getImages()}
                    >
                        <span className="dashboardLinkText">Dashboard </span>
                    </Link>
                    <span>/</span>
                    <span className="slashPath ms-1">
                        {parentFile.parent_file_name}
                    </span>
                    <div>
                        <Link
                            type="button"
                            className="btn btn-primary backButton ms-3"
                            to={`${basePath}/Dashboard`}
                            onClick={() => getImages()}
                        >
                            <i className="backArrow"></i>
                            <span>{parentFile.parent_file_name}</span>
                        </Link>
                    </div>
                </>
            ) : (
                <div className="dashboardTitle d-flex flex-column align-items-start">
                    Dashboard
                </div>
            )}
            {!fetchAPI && <div className="custom-loader"></div>}
            {fetchAPI && (
                <>
                    {images == undefined ? (
                        <>
                            <div className="dashboard-upload-container container mx-auto d-flex align-items-center mt-1">
                                <div className="upload-inner-container inner-container mx-auto">
                                    {isAdminLogin ? (
                                        <>
                                            <div className="noFiles-instruction mb-2">
                                                No Files to Show
                                            </div>
                                            <div className="files-upload-instruction mb-2">
                                                Kindly upload your images / files to generate the Alt
                                                Text
                                            </div>
                                            <button className="upload-btn mb-2" onClick={upload}>
                                                Upload
                                            </button>
                                        </>
                                    ) : (
                                        <div className="noFiles-instruction mb-2">
                                            No Files have been Assigned
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="searchText d-flex flex-wrap mb-2">
                                <div className="search col-md-4 col-lg-3">
                                    <div className="input-icons">
                                        <input
                                            type="text"
                                            className="inputIcon form-control mb-2"
                                            placeholder="Search for file name"
                                            value={search}
                                            onChange={(e) =>
                                                e.target.value.length <= 100 &&
                                                setSearch(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="statusDD col-md-3 col-lg-2">
                                    <div className="dropdown-status mb-2">
                                        <button
                                            className="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <span className="status">Status: </span>
                                            <span className="selected-status">
                                                {selectedStatus === "P"
                                                    ? "Pending"
                                                    : selectedStatus === "I"
                                                        ? "In Progress"
                                                        : selectedStatus === "D"
                                                            ? "Draft"
                                                            : selectedStatus === "R"
                                                                ? "Reviewed"
                                                                : selectedStatus === "F"
                                                                    ? "Failed"
                                                                    : "All"}
                                            </span>
                                            {selectedStatus !== "All" && (
                                                <span className="tick-mark"></span>
                                            )}
                                            {/* <span className="all">{filterValue === "D" ? "Draft" : filterValue === "R" ? "Reviewed" : 'All'}</span> */}
                                        </button>
                                        <ul
                                            className="dropdown-menu"
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            <li>
                                                <a
                                                    className={`dropdown-item${selectedStatus === "All" ? " active" : ""
                                                        }`}
                                                    href="#"
                                                    onClick={() => handleStatusFilter("All")}
                                                >
                                                    All
                                                    {selectedStatus === "All" && (
                                                        <span className="tick-mark"></span>
                                                    )}
                                                </a>
                                            </li>
                                            {isAdminLogin ? (
                                                <>
                                                    <li>
                                                        <a
                                                            className={`dropdown-item${selectedStatus === "P" ? " active" : ""
                                                                }`}
                                                            href="#"
                                                            onClick={() => handleStatusFilter("P")}
                                                        >
                                                            Pending
                                                            {selectedStatus === "P" && (
                                                                <span className="tick-mark"></span>
                                                            )}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className={`dropdown-item${selectedStatus === "I" ? " active" : ""
                                                                }`}
                                                            href="#"
                                                            onClick={() => handleStatusFilter("I")}
                                                        >
                                                            In Progress
                                                            {selectedStatus === "I" && (
                                                                <span className="tick-mark"></span>
                                                            )}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className={`dropdown-item${selectedStatus === "D" ? " active" : ""
                                                                }`}
                                                            href="#"
                                                            onClick={() => handleStatusFilter("D")}
                                                        >
                                                            Draft
                                                            {selectedStatus === "D" && (
                                                                <span className="tick-mark"></span>
                                                            )}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className={`dropdown-item${selectedStatus === "R" ? " active" : ""
                                                                }`}
                                                            href="#"
                                                            onClick={() => handleStatusFilter("R")}
                                                        >
                                                            Reviewed
                                                            {selectedStatus === "R" && (
                                                                <span className="tick-mark"></span>
                                                            )}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className={`dropdown-item${selectedStatus === "F" ? " active" : ""
                                                                }`}
                                                            href="#"
                                                            onClick={() => handleStatusFilter("F")}
                                                        >
                                                            Failed
                                                            {selectedStatus === "F" && (
                                                                <span className="tick-mark"></span>
                                                            )}
                                                        </a>
                                                    </li>
                                                </>
                                            ) : (
                                                <>
                                                    <li>
                                                        <a
                                                            className={`dropdown-item${selectedStatus === "D" ? " active" : ""
                                                                }`}
                                                            href="#"
                                                            onClick={() => handleStatusFilter("D")}
                                                        >
                                                            Draft
                                                            {selectedStatus === "D" && (
                                                                <span className="tick-mark"></span>
                                                            )}
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className={`dropdown-item${selectedStatus === "R" ? " active" : ""
                                                                }`}
                                                            href="#"
                                                            onClick={() => handleStatusFilter("R")}
                                                        >
                                                            Reviewed
                                                            {selectedStatus === "R" && (
                                                                <span className="tick-mark"></span>
                                                            )}
                                                        </a>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                                <div className="datePick col-md-4 col-lg-3">
                                    <DatePicker
                                        showIcon
                                        selected={selectedDate}
                                        onChange={(date: any) => {
                                            setSelectedDate(date)
                                            filterImages(images, filterValue, date)
                                        }}
                                        placeholderText="mm/dd/yyyy"
                                        className="datePicker mb-2"
                                        closeOnScroll={true}
                                        dateFormat="MM/dd/yyyy"
                                        isClearable
                                        onChangeRaw={(date: any) =>
                                            handleChangeRaw(date, Event)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="dataTable">
                                <DataTable
                                    customStyles={tableCustomStyles}
                                    title={
                                        <div className="custom-title">
                                            Total files ({filteredImages.length}{" "}
                                            {filteredImages.length > 1 ? "files" : "file"})
                                        </div>
                                    }
                                    columns={columns}
                                    data={filteredImages}
                                    pagination
                                    paginationPerPage={25}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    responsive={true}
                                    // fixedHeader
                                    // fixedHeaderScrollHeight='250px'
                                    selectableRows
                                    noContextMenu
                                    onRowClicked={handleRowClicked}
                                    // contextActions={contextActions}
                                    // selectableRowDisabled={(row: any) => row.status === 'I'}
                                    onSelectedRowsChange={handleRowSelected}
                                    clearSelectedRows={toggleCleared}
                                    selectableRowsHighlight
                                    highlightOnHover
                                    actions={
                                        filteredImages.length > 0 && (
                                            <>
                                                <button
                                                    className="btn btn-sm btn-info refresh-btn"
                                                    onClick={() => handleRefresh()}
                                                >
                                                    <i className="bi bi-bootstrap-reboot refresh-icon"></i>
                                                    Refresh
                                                </button>
                                                {isAdminLogin && (
                                                    <button
                                                        className="btn btn-sm btn-info delete-btn"
                                                        onClick={handleDelete}
                                                    >
                                                        <i className="trashIcon"></i>Delete
                                                    </button>
                                                )}
                                                {selectedForExport.length > 0 ? (
                                                    <button className="btn btn-sm btn-info export-btn">
                                                        <CSVLink data={selectedForExport} filename="imageData.csv">
                                                            <i className="exportIcon"></i>Export
                                                        </CSVLink>
                                                    </button>
                                                ) : (

                                                    <button
                                                        className="btn btn-sm btn-info export-btn"
                                                        onClick={exportCSV}
                                                    >
                                                        <i className="exportIcon"></i>Export
                                                    </button>
                                                )}
                                                {/* <button className="btn btn-sm btn-info import-btn"><i className="importIcon"></i>Import</button> */}
                                                {isAdminLogin && (
                                                    <button
                                                        className="btn btn-sm btn-info assign-btn"
                                                        onClick={handleAssign}
                                                    >
                                                        <i className="assignIcon"></i>Assign
                                                    </button>
                                                )}
                                            </>
                                        )
                                    }
                                />
                            </div>

                            {showModal && (
                                <ConfirmationModal
                                    action={modalAction}
                                    text={modalText}
                                    header={modalHeader}
                                    showInput={showInput}
                                    onConfirm={handleModalConfirm}
                                    onCancel={handleModalCancel}
                                    confirmButtonDisabled={deleting}
                                    userDetails={userDetails}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default ImageTable
