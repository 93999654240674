import React from 'react'
import "./Footer.scss"

const Footer = () => {
  return (
    <footer className="d-flex flex-wrap justify-content-between align-items-center py-4 border-top mx-auto">
    <div className="col-md-4 d-flex align-items-center copyrightLogo">
              <img width={125} src={require('../../assets/images/logo-exacttag-beta-black-footer.png')} alt="logo" className="src" />
            </div>
            <div className='col-md-4 d-flex justify-content-end copyright-text'>
              Copyright 2023 exacttag.ai. All Rights Reserved.
            </div>
        </footer>
  )
}
export default Footer
