import React, { useEffect } from "react"
import "./DashboardContainer.scss"
import Header from "../Header/Header"
import Footer from "../Footer/Footer"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer, cssTransition } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
interface DashBoardProps {
  isLoggedIn: boolean
  onLogout: () => void
  children: React.JSX.Element
  generateRandomKey: () => void
  onSessionExpired: () => void
}
const DashboardContainer = (props: DashBoardProps) => {
  const navigate = useNavigate()
  const apiBasePath = process.env.REACT_APP_API_URL
  const basePath = process.env.REACT_APP_BASE_PATH

  const padZero = (num: any) => {
    return num.toString().padStart(2, "0")
  }
  const getCurrentTime = (tokenExpiry: number) => {
    const currentTime = new Date()
    const updatedTime = new Date(currentTime)
    updatedTime.setHours(updatedTime.getHours() + tokenExpiry)
    const year = updatedTime.getFullYear()
    const month = padZero(updatedTime.getMonth() + 1)
    const date = padZero(updatedTime.getDate())
    const hours = updatedTime.getHours()

    const minutes = updatedTime.getMinutes()
    const seconds = updatedTime.getSeconds()
    const formattedUpdatedTime = `${year}-${month}-${date} ${padZero(
      hours
    )}:${padZero(minutes)}:${padZero(seconds)}`
    return formattedUpdatedTime
  }

  useEffect(() => {
    const refreshTokenApi = (event: any) => {
      const clickedElement = event.target
      console.log(clickedElement, "clickedElement---")
      const currentTime = getCurrentTime(0)
      const isExcludedDiv =
        clickedElement.classList &&
        (clickedElement.classList.contains("logout") ||
          clickedElement.classList.contains("logout__text"))
      if (isExcludedDiv) return
      console.log(currentTime, "ZZZZ=======")

      console.log("call refresh token here")
      const refreshToken = localStorage.getItem("refresh_token")
      const expiry_time = localStorage.getItem("expires_at")
      console.log(expiry_time, "expiry_time===")

      if (refreshToken !== null && expiry_time !== null) {
        const [hh, mm, ss] = expiry_time.split(" ")[1].split(":")
        const expiryDay = expiry_time.split(" ")[0].split("-")[2]
        console.log(currentTime, "currentTime")

        const [ctHH, ctMM, ctSS] = currentTime.split(" ")[1].split(":")
        const ctDay = currentTime.split(" ")[0].split("-")[2]
        // console.log(ctDay,'ctDay');

        const hoursToSeconds_Expire_time =
          Number(hh) * 60 * 60 + Number(mm) * 60 + Number(ss)
        const hoursToSeconds_Current_time =
          Number(ctHH) * 60 * 60 + Number(ctMM) * 60 + Number(ctSS)

        console.log(
          hoursToSeconds_Expire_time,
          "hoursTomiliSeconds--",
          hoursToSeconds_Current_time,
          ctDay,
          expiryDay,
          "currentTime",
          currentTime
        )
        const isExpired =
          hoursToSeconds_Current_time >= hoursToSeconds_Expire_time &&
          Number(ctDay) >= Number(expiryDay)
        console.log(isExpired, "isExpired")

        if (isExpired) {
          axios
            .post(`${apiBasePath}api/refreshToken`, {
              refresh_token: refreshToken
            })
            .then((response) => {
              const data = response.data
              const token = data.token
              const refreshToken = data.refresh_token
              const renewExpiryTime = getCurrentTime(1)
              if (refreshToken && data.status === 200) {
                localStorage.setItem("user-token", token)
                localStorage.setItem("expires_at", renewExpiryTime)
              }
            })
            .catch((error) => {
              console.log(error)
              if (error.response.status === 401) {
                props.onSessionExpired()
                localStorage.clear()
                navigate(`${basePath}`)
              }
            })
        }
      }
    }
    document.addEventListener("click", refreshTokenApi)
    window.addEventListener("load", refreshTokenApi)

    return () => {
      document.removeEventListener("click", refreshTokenApi)
      window.removeEventListener("load", refreshTokenApi)
    }
  }, [])
  const CloseButton = ({ closeToast }: any) => (
    <div className="error-close-btn" onClick={closeToast}></div>
  )
  // const uploadErrorClose = ({ closeToast }: any) => (
  //   <div className="error-close-btn-1" onClick={closeToast}></div>
  // )

  return (
    <>
      <ToastContainer
        enableMultiContainer
        containerId={"Error"}
        hideProgressBar={true}
        className="container-error"
        toastClassName="error-wrapper"
        autoClose={false}
        bodyClassName="notification-error-body"
        closeButton={CloseButton}
      />
      <ToastContainer
        enableMultiContainer
        containerId={"Success"}
        hideProgressBar={true}
        className="container-upload"
        toastClassName="wrapper-upload"
        autoClose={false}
        bodyClassName="notification-body-upload"
        closeButton={CloseButton}
      />
      <Header
        onLogout={props.onLogout}
        generateRandomKey={props.generateRandomKey}
      />
      <>{props.children}</>
      <Footer />
    </>
  )
}

export default DashboardContainer
