import React from 'react';
import { Tag } from './tag';
import {
    Popover,
    PopoverHandler,
    PopoverContent,
  } from "@material-tailwind/react";

interface TagListProps { 
    tags: string[];
    initialTagCount: number;
    onTagClick: (tagTitle: string) => void;
}

function TagList({ tags, initialTagCount, onTagClick }: TagListProps) {
    const initialTags = tags.slice(0 , initialTagCount);
    const remaintingTags = tags.slice(initialTagCount);
    
    return (
        <>
               <div className="tag-list">
            {initialTags.map((tag, index) => <Tag key={index} title={tag} />)}
            {remaintingTags.length > 0 && 
             <Popover placement="top">
             <PopoverHandler>
                <button className='tag' style={{ backgroundColor: '#151515', color: '#ffffff' }}>{remaintingTags.length} More</button>
            </PopoverHandler>
            <PopoverContent>
                <div>
                <div className="preview-image mb-3">
                <span>Keywords/Tags</span>
                <span></span>
              </div>
                </div>
            <div className="tag-list" style={{ display: 'flex', 
            // width: '527px',height: '299px',
             overflow: 'auto'}}>
                {remaintingTags.map((tag, index) => <Tag key={index} title={tag} /> )}
                </div>
      </PopoverContent>
            </Popover>}
        </div>
        </>
    )
}

export default TagList;