import React from "react"

const LeftContainer = () => {
  return (
    <div className="col-sm-12 col-xs-12 col-md-12 col-lg-5 left-container">
      <div className=" left-container__img">
        <div className="col-sm-12 col-xs-12 col-md-10">
          <div className="row">
            <div className="col-sm-12 col-xs-12 col-md-12 left-container__logoWhite">
              <img width={200}
                src={require("../../assets/images/logo-exacttag-beta-white.png")}
                alt="Brand Logo"
              />
            </div>
          </div>
        </div>
        <div className="row left-container__parentRow d-flex justify-content-center">
          <div className="col-sm-12 col-xs-12 col-md-10 ver-center-xl">
            <div className="row">
                <img
                  src={require("../../assets/images/Img-login-left.png")}
                  alt="123"
                />
            </div>
            <div className="row justifyCenter">
              <div className="col-sm-12 col-xs-12 col-md-11 left-container__title1">
                  Automated Meta Descriptions
              </div>
            </div>
            <div className="row justifyCenter">
              <div className="col-sm-12 col-xs-12 col-md-11 left-container__desc1">
                  Effortlessly craft impactful meta descriptions from uploaded files using ExactTag Product
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftContainer
