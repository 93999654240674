import React from "react";

interface TapProps { 
    title: string
}
  
  export function Tag({ title }: TapProps) {
    return ( 
      <div className="tag">
        <span>{title}</span>
        <span style={{ marginLeft: '12px' }}>&#10006;</span>
      </div>
     );
  }
  