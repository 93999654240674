import "./App.css"
import React, { useState } from "react"
import { Routes, Route } from "react-router-dom"
import Login from "./components/Login/Login"
import DashboardContainer from "./components/DashboardContainer/DashboardContainer"
import Upload from "./components/Upload/Upload"
import Dashboard from './components/Dashboard/dashboard'
import AltTextDetails from './components/ImageTable/altTextDetails'
import ChangePassword from "./components/ChangePassword/ChangePassword"
import ForgetPassword from "./components/ForgetPassword/ForgetPassword"
import ResetPassword from "./components/ResetPassword/ResetPassword"
import { ProtectedRoute, PublicRoute } from "./util/ProtectedRoute"

function App() {
  const [randomKey, setRandomKey] = useState(0)
  const [loggedIn, setloggedIn] = useState(false)
  const [loggedOut, setloggedOut] = useState(false)
  const [sessionExpired, setSessionExpired] = useState(false)

  const setDefault = () =>{
    setloggedIn(false)
    setloggedOut(false)
  }
  const onLogin = () => {
    setloggedIn(true)
    setloggedOut(false)
  }
  const onLogout = () => {
    setloggedIn(false)
    setloggedOut(true)
  }
  const onSessionExpired = () => {
    setloggedOut(true)
    setSessionExpired(true)

  }
  const generateRandomKey =() => {
    setRandomKey(key=> key === 0 ? 1 : 0)
  }
  
  const basePath = process.env.REACT_APP_BASE_PATH
  return (
    <>
      <Routes>
        <Route
          exact
          path={basePath}
          element={
            <PublicRoute>
              <Login onLogin={onLogin} isloggedOut={loggedOut} sessionExpired={sessionExpired}/>
            </PublicRoute>
          }
        />
        <Route
        exact
        path={`${basePath}/ForgetPassword`}
        element={
          <PublicRoute>
            <ForgetPassword setDefault={setDefault}/>
          </PublicRoute>
        }
      />
      <Route
        exact
        path={`${basePath}/ResetPassword/:email`}
        element={
          <PublicRoute>
            <ResetPassword setDefault={setDefault}/>
          </PublicRoute>
        }
      />
        <Route
          exact
          path={`${basePath}/Upload`}
          element={
            <ProtectedRoute>
              <DashboardContainer isLoggedIn={loggedIn} onLogout={onLogout} onSessionExpired={onSessionExpired} generateRandomKey={generateRandomKey}>
                <Upload randomKey={randomKey}/>
              </DashboardContainer>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={`${basePath}/Dashboard`}
          element={
            <ProtectedRoute>
              <DashboardContainer isLoggedIn={loggedIn} onLogout={onLogout} onSessionExpired={onSessionExpired} generateRandomKey={generateRandomKey}>
                <Dashboard randomKey={randomKey} />
              </DashboardContainer>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={`${basePath}/Dashboard/:parentId`}
          element={
            <ProtectedRoute>
              <DashboardContainer isLoggedIn={loggedIn} onLogout={onLogout} onSessionExpired={onSessionExpired} generateRandomKey={generateRandomKey}>
                <Dashboard randomKey={randomKey} />
              </DashboardContainer>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={`${basePath}/altTextDetails/:id`}
          element={
            <ProtectedRoute>
              <DashboardContainer isLoggedIn={loggedIn} onLogout={onLogout} onSessionExpired={onSessionExpired} generateRandomKey={generateRandomKey}>
              <AltTextDetails />
              </DashboardContainer>
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path={`${basePath}/ChangePassword`}
          element={
            <ProtectedRoute>
              <DashboardContainer isLoggedIn={loggedIn} onLogout={onLogout} onSessionExpired={onSessionExpired}  generateRandomKey={generateRandomKey}>
                <ChangePassword />
              </DashboardContainer>
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  )
}

export default App
