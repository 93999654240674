import React, { useState, useEffect, useRef } from 'react';
import "./ConfirmationModal.scss";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const ConfirmationModal = ({ action, text, header, showInput, onConfirm, onCancel, userDetails } : any) => {
    const [recipient, setRecipient] = useState('')
    const [users, setUsers] = useState(userDetails)
    const modalContentRef = useRef<HTMLDivElement>(null)
    const cancelButtonRef = useRef<HTMLButtonElement>(null) // Create a ref for the Cancel button

  const handleRecipientChange = (e: any) => {
    setRecipient(e.target.value)
  }
  useEffect(() => {
    if (action && cancelButtonRef.current) {
      cancelButtonRef.current?.focus() 
      setTimeout(() => {
        cancelButtonRef.current?.focus() // Autofocus the Cancel button when the modal opens
      }, 1000)
    }
  }, [action])
  const value: string = ''
  useEffect(() => {
    if (userDetails.length > 0) {
      const transformedUsers = userDetails.map((email:any, index:any) => ({
        name: email,
        id: index.toString()
      }))
      setUsers(transformedUsers)
    }

  }, [userDetails])

  // Function to handle focus and restrict it within the modal content container
  const handleTabKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Tab') {
      e.preventDefault()
      const focusableElements = modalContentRef.current?.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])') as NodeListOf<HTMLElement>
      const firstFocusable = focusableElements[0]
      const lastFocusable = focusableElements[focusableElements.length - 1]

      if (!e.shiftKey && document.activeElement === lastFocusable) {
        // If Shift key is not pressed and the focus is on the last focusable element, move focus to the first focusable element.
        firstFocusable?.focus()
      } else if (!e.shiftKey && document.activeElement === firstFocusable) {
        // If Shift key is pressed and the focus is on the first focusable element, move focus to the last focusable element.
        lastFocusable?.focus()
      }
    }
  }

  useEffect(() => {
    // Add event listener to handle the tab key press.
    modalContentRef.current?.addEventListener('keydown', handleTabKey as any)

    // Remove the event listener when the component unmounts.
    return () => {
      modalContentRef.current?.removeEventListener('keydown', handleTabKey as any)
    }
  }, [])
  return (
    <div className="modalBox">
      <div ref={modalContentRef} className={showInput ? 'assign-modal-content' : 'modal-content'} tabIndex={0}>
        {!showInput && (
          <>
            <h2 dangerouslySetInnerHTML={{ __html: header }}></h2>
            <p dangerouslySetInnerHTML={{ __html: text }}></p>
          </>
        )}
        {showInput && (
          <>
            <h2 className='assignModal m-0' dangerouslySetInnerHTML={{ __html: header }}></h2>
            <div className='assignModalBody'>
              <p dangerouslySetInnerHTML={{ __html: text }}></p>
              <ReactSearchAutocomplete
              showIcon={false}
              className='autocomplete-input'
                items={users}
                placeholder="Email Address"
                styling={{ zIndex: 4 }} // To display it on top of the search box below
                autoFocus
              />
            </div>
          </>
        )}
        <div className={showInput ? 'assign-modal-buttons' : 'modal-buttons'}>
          <button ref={cancelButtonRef} className="btn btn-secondary cancel-button" onClick={onCancel}>
            Cancel
          </button>
          <button className="btn btn-danger confirm-button" onClick={() => onConfirm(recipient)}>
            {action}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationModal
