import React, { useState, useEffect } from "react"
import { Link, useParams, useNavigate } from "react-router-dom"
import axios from "axios"
import { toast, cssTransition } from "react-toastify"
import TagList from "./tagList"
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
const AltTextDetails = () => {
  const navigate = useNavigate()
  const [imageData, setImageData] = useState({
    author: "NA",
    file_name: "",
    file_path: "",
    original_file_path: "",
    image_status: "",
    assigned_user: "",
    user: "",
    publisher: "NA",
    publishing_date: "NA",
    created_at: "",
    final_meta_data: {
      ColorProfile: "",
      Compression: "",
      Copyright: "",
      DateTime: "",
      Description: "",
      Dimensions: [],
      ExtraMetaData: {},
      FileType: "",
      Format: "",
      Keywords: [],
      Make: "",
      Model: "",
      Size: "",
      Title: "",
      XResolution: "",
      YResolution: ""
    },
    image_id: "",
    parent_id: ""
  })
  const [assetType, setAssetType] = useState(false);
  const [parentFileName, setParentFileName] = useState({
    parent_file_name: ""
  })

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    author: "",
    publisher: "",
    date_of_creation: "",
    date_of_publication: ""
  })

  // TODO
  const [detailsData, setDetailsData] = useState({
    alt_text: "",
    alt_text_2: "",
    alt_text_3: "",
    alt_text_4: "",
    file_name: "",
    file_path: "",
    image_id: "",
    status: "",
    user_id: ""
  })
  const handleChangeRaw = (date: any, event: any) => {
    if (date.type !== "click") {
      const inputValue = date.currentTarget.value
      const sanitizedValue = inputValue.replace(/[^0-9/]/g, "").substr(0, 10)
      date.currentTarget.value = sanitizedValue
    }
  }
  const {
    alt_text,
    alt_text_2,
    alt_text_3,
    alt_text_4,
    file_name,
    file_path,
    image_id,
    status,
    user_id
  } = detailsData
  const [filterLanguage, setFilterLanguage] = useState("English")
  const [apiError, setApiError] = useState(true)
  const [altText, setAltText] = useState<any>("")
  const [selectedOption, setSelectedOption] = useState<number | null>(null)
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [prevData, setPrevData] = useState("")
  const [nextData, setNextData] = useState("")
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [dateValidationError, setDateValidationError] = useState<string | null>(
    null
  )

  const [zipDataPath, setZipDataPath] = useState(false)
  const [isTextAreaChanged, setIsTextAreaChanged] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showPreview, setShowPreview] = useState(false)
  const apiBasePath = process.env.REACT_APP_API_URL
  const basePath = process.env.REACT_APP_BASE_PATH
  const saveId = "toast-id-save"
  let detailsText: any = ""
  const { id } = useParams()
  // asset_id
  console.log(id, "asset_id")

  const Enter = cssTransition({
    enter: "fade-in",
    exit: "fade-out",
    appendPosition: false,
    collapse: true,
    collapseDuration: 300
  })

  const successSaveNotification = (successMessage: string) => {
    toast.success(successMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: saveId,
      transition: Enter,
      containerId: "Success"
    })
  }

  const parseDate = (dateString: string | null) => {
    if (!dateString) {
      return null
    }

    const parts: string[] = dateString.split("-")
    console.log(`printing parts: ${parts}`)
    if (parts.length === 3) {
      // Parse the day, month, and year parts
      const year = parseInt(parts[0], 10)
      const month = parseInt(parts[1], 10) - 1 // Subtract 1 from the month because months are 0-based (0 = January, 1 = February, etc.)
      const day = parseInt(parts[2], 10)

      // Create a new Date object
      const date = new Date(year, month, day)
      return date
    }

    // Return null for invalid dates
    return null
  }

  const getDateString = (date: Date | null) => {
    if (!date) {
      return ""
    }

    const day = date.getDate()
    const month = date.getMonth() + 1

    return date
      ? `${day < 10 ? "0" + day : day}/${
          month < 10 ? "0" + month : month
        }/${date.getFullYear()}`
      : ""
  }

  const loadUserData = async (id: any) => {
    let isChecked = true
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null)
      axios
        .post(
          `${apiBasePath}api/getSingleImageData`,
          {
            image_id: id
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          }
        )
        .then((response) => {
          const data = response.data.data
          const responseData = response.data
          console.log("pritning response for getSingleImageData")
          console.log(response.data.data)
          if (response.status === 200) {
            if (data !== undefined && data.final_meta_data.Title !== undefined) {
              setImageData(data)
              if (data.file_name.includes(".jpeg") || data.file_name.includes(".jpg") || data.file_name.includes(".png")) {
                setAssetType(false)
              } else {
                setAssetType(true)
              }
              setImageData((prevData) => ({
                ...prevData,
                created_at: getDateString(parseDate(data.created_at)),
                final_meta_data: {
                  ...prevData.final_meta_data,
                  Title: data.final_meta_data.Title,
                  Description: data.final_meta_data.Description,
                }
              }))
              setNextData(response.data.next_id)
              setPrevData(response.data.prev_id)
              const altTextWithValueOne = data.alt_text.find(
                (altText: any) => altText.default_value === 1
              )

              if (altTextWithValueOne) {

                const selectedOptionIndex = data.alt_text.findIndex(
                  (altText: any) => altText.id === altTextWithValueOne.id
                )
                setSelectedOption(selectedOptionIndex)
                setAltText(altTextWithValueOne.modal)
              }
              
              if (data.image_status === "R") {
                setSelectedOption(null)
              }
              if (data.parent_id !== "0") {
                setZipDataPath(true)
                setParentFileName(responseData)
              }
              
              // Set final_alt_text as altText
              // setAltText(data.final_alt_text)
              console.log(data, ",", data[0])
            } else {
              // setFetchAPI(false)
              navigate(`${basePath}/Dashboard`)
            }
          }
        })
        .catch((error) => {
          console.log(error)
          // setApiError(true)
        })
  }

  useEffect(() => {
    loadUserData(id)
    console.log(detailsData, imageData)
  }, [])
  console.log(detailsData, "data", detailsData)

  const filterLanguageValue = (imagesData: any, filter: any) => {
    // if (filter === 'English') {
    //   setFilterLanguage(imagesData)
    // } else {
    //   const filtered = imagesData.filter((image: { status: any }) => image.status === filter)
    //   setFilterLanguage(filtered)
    // }
  }
  const handleRadioClick = (index: number) => {
    // Reset the saved state
    setSaved(false)
    if (detailsText !== "") {
      detailsText = ""
    }
    detailsText = document.querySelector(`.form-control-details__${index}`)
    console.log(detailsText, "DETAILS TEXT")
    toast.dismiss()
    setAltText("")
    setAltText(detailsText.textContent)
  }
  const handleAltTextChange = (event: any) => {
    toast.dismiss()
    const { name, value } = event.target
    // setFormData((prevData) => ({
    //   ...prevData,
    //   [name]: value
    // }))
    setImageData((prevData) => ({
      ...prevData,
      [name]: value,
      final_meta_data: {
        ...prevData.final_meta_data,
        [name]: value
      }
    }))
    // Set isTextAreaChanged to true when there's a change in the textarea
    // setIsTextAreaChanged(true)
    // Reset the saved state
    setSaved(false)
  }

  const handleSave = () => {
    setSaving(true)
    const requestData = {
      image_id: imageData.image_id,
      meta_data : imageData.final_meta_data
    }

    axios
      .post(
        `${apiBasePath}api/setImageReviewed`,
         requestData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("user-token")}`
          }
        }
      )
      .then((response) => {
        // Handle the response if needed
        console.log(response)
        if (response.status === 200) {
          setSaving(false)
          setSaved(true)
          successSaveNotification(response.data.message)
          // Reset the saved state after a certain duration if needed
          setTimeout(() => {
            setSaved(false)
            // Update the image status to "Reviewed"
            // setAltTextData((prevData) => ({
            //   ...prevData,
            //   image_status: "R",
            // }))
            setSelectedOption(null)
          }, 2000) // Reset after 2 seconds (adjust the duration as per your requirement)
        }
      })
      .catch((error) => {
        // Handle the error if needed
        console.log(error)
        setSaving(false)

        if (error.response && error.response.status === 401) {
          // Show toast error message when the data is deleted (401 Unauthorized)
          toast.error("Image not found. Please refresh the page.", {
            position: toast.POSITION.TOP_CENTER,
            // eslint-disable-next-line
            icon: false,
            transition: Enter,
            containerId: "Error"
          })
        } else {
          // Show a generic error message for other errors
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_CENTER,
            // eslint-disable-next-line
            icon: false,
            transition: Enter,
            containerId: "Error"
          })
        }
      })
  }

  useEffect(() => {
    filterLanguageValue(detailsData, filterLanguage)
    // console.log(altTextData, '----');
  }, [filterLanguage])

  useEffect(() => {
    if (nextData === null) {
      document.querySelector(".next-btn")?.classList.add("disableNext")
    } else {
      document.querySelector(".next-btn")?.classList.remove("disableNext")
    }
    if (prevData === null) {
      document.querySelector(".prev-btn")?.classList.add("disablePrev")
    } else {
      document.querySelector(".prev-btn")?.classList.remove("disablePrev")
    }
  })
  const navigateToPrevData = () => {
    toast.dismiss()
    // setIsTextAreaChanged(false)
    if (prevData !== null) {
      navigate(`${basePath}/altTextDetails/${prevData}`)
      loadUserData(prevData)
    }
  }
  const navigateToNextdata = () => {
    toast.dismiss()
    // setIsTextAreaChanged(false)
    if (nextData !== null) {
      navigate(`${basePath}/altTextDetails/${nextData}`)
      loadUserData(nextData)
    }
  }

  const backToDashboard = () => {
    toast.dismiss()
    if (imageData.parent_id !== "0") {
      navigate(`../${basePath}/Dashboard/${imageData.parent_id}`)
    } else {
      navigate(`../${basePath}/Dashboard`)
    }
  }

  // Function to handle opening and closing of the modal
  const handleModalToggle = () => {
    setShowModal(!showModal)
  }

  // Function to handle showing and hiding the preview
  const handleShowPreview = () => {
    setShowPreview(true)
  }

  const handleHidePreview = () => {
    setShowPreview(false)
  }
  const handleDateChange = (date: Date | null) => {
    // const validationError = validateDate(date)
    // setDateValidationError(validationError)
    setSelectedDate(date)
    setFormData((prevData) => ({
      ...prevData,
      date_of_publication: getDateString(date)
    }))
  }
  const validateDate = (date: Date | null) => {
    if (!date) {
      return "Please select a date."
    }
    if (date < parseDate(formData.date_of_creation)!) {
      return "Please select a valid date."
    }
    return null
  }
  const handleDateBlur = (_: React.FocusEvent<HTMLInputElement, Element>) => {
    const validationError = validateDate(selectedDate)
    setDateValidationError(validationError)
  }

  // console.log(imageData, "imageData")
  console.log(imageData.final_meta_data, "imageData.final_meta_data")

  return (
    <div className="imageDetails container mt-4">
      {zipDataPath ? (
        <>
          <Link
            type="button"
            className="btn btn-primary dashboardPath mb-1 me-1"
            to={`${basePath}/Dashboard`}
          >
            <span className="dashboardLinkText">Dashboard </span>
          </Link>
          <span>/</span>
          <span className="slashPath ms-1">
            {parentFileName.parent_file_name}
          </span>
          <span> /</span>
          <span className="slashPath ms-1">{imageData.file_name}</span>
        </>
      ) : (
        <>
          <Link
            type="button"
            className="btn btn-primary dashboardPath mb-1 me-1"
            to={`${basePath}/Dashboard`}
          >
            <span className="dashboardLinkText">Dashboard </span>
          </Link>
          <span>/</span>
          <span className="slashPath ms-1">{imageData.file_name}</span>
        </>
      )}
      <div>
        <span
          className="btn btn-primary backButton ms-3"
          onClick={backToDashboard}
        >
          <i className="backArrow"></i>
          <span>Metatag Description</span>
        </span>
        <div className="navigation mb-3">
          <button
            className="btn btn-sm btn-info prev-btn"
            onClick={navigateToPrevData}
          >
            <i className="prevIcon"></i>Prev
          </button>
          <button
            className="btn btn-sm btn-info next-btn"
            onClick={navigateToNextdata}
          >
            <i className="nextIcon"></i>Next
          </button>
        </div>
      </div>
      <div className="container altTextDetails">
        <div className="row p-3">
          <div className="column col-4">
            {/* Thumbnail Image */}
            <div
              className="image-preview-wrapper"
              onMouseEnter={handleShowPreview}
              onMouseLeave={handleHidePreview}
            >
              <img
                src={imageData.file_path}
                alt={imageData.file_name}
                className="img-fluid generatedImage clickable-image-thumbnail"
              />
              {showPreview && (
                <div className="image-preview">
                  <div className="img-fluid preview-image"></div>
                  <span className="preview-text" onClick={handleModalToggle}>
                    <i className="full-screen-icon"></i>Preview
                  </span>
                  {/* <img src={altTextData.file_path} alt={altTextData.file_name} className="img-fluid preview-image" /> */}
                </div>
              )}
            </div>
            {/* <img src={altTextData.file_path} alt={altTextData.file_name} className="img-fluid generatedImage mb-2"
            onClick={handleModalToggle} /> */}
            {imageData.assigned_user !== null && (
              <div className="assignedTo mt-3">
                Assigned to:{" "}
                <span className="email mt-1">{imageData.assigned_user}</span>
              </div>
            )}
            <div
              className="alt-text-status mt-3"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                style={{
                  marginRight: "10px",
                  height: "39px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                Status:
              </span>
              <span
                style={{ width: "114px", height: "39px" }}
                className={`alt-text-file-status mt-1 ${
                  imageData.image_status === "D"
                    ? "draft-btn"
                    : imageData.image_status === "R"
                    ? "reviewed-btn"
                    : ""
                }`}
              >
                {imageData.image_status === "D"
                  ? "Draft"
                  : imageData.image_status === "R"
                  ? "Reviewed"
                  : ""}
              </span>
            </div>
          </div>
          <div className="column col-8">
            <div className="form-subheading">General</div>
            <div className="form-group formControlTextArea">
              <label htmlFor="title" className="form-field-label">
                Title
              </label>
              <input
                className="form-control"
                id="title"
                name="Title"
                value={imageData.final_meta_data.Title}
                onChange={handleAltTextChange}
              />
            </div>

            <div className="form-group formControlTextArea">
              <label htmlFor="description" className="form-field-label">
                Description
              </label>
              <input
                style={{ height: "76px", wordSpacing: "5px !important" }}
                className="form-control"
                id="description"
                name="Description"
                value={imageData.final_meta_data.Description}
                onChange={handleAltTextChange}
              />
            </div>

            <div className="form-subheading">More Info</div>

            <div className="form-group formControlTextArea">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-field-label"
              >
                Keywords/Tags
              </label>
              {imageData.final_meta_data !== null && (
                <TagList
                  tags={imageData.final_meta_data.Keywords}
                  initialTagCount={8}
                  onTagClick={(tag) => console.log(tag)}
                />
              )}
            </div>
            {assetType && (
              <div className="form-group formControlTextArea">
              <label htmlFor="author" className="form-field-label">
                Author / Creator
              </label>
              <input
                className="form-control"
                id="author"
                name="author"
                value={imageData.author}
                onChange={handleAltTextChange}
                readOnly
              />
            </div>
            )}
             {assetType && (
            <div className="form-group formControlTextArea">
              <label htmlFor="publisher" className="form-field-label">
                Publisher
              </label>
              <input
                className="form-control"
                id="publisher"
                name="publisher"
                value={imageData.publisher}
                onChange={handleAltTextChange}
                readOnly
              />
            </div>
            )}
            <div className="form-group formControlTextArea">
              <label htmlFor="date_of_creation" className="form-field-label">
                Date of Creation
              </label>
              <input
                className="form-control"
                id="date_of_creation"
                name="date_of_creation"
                value={imageData.created_at}
                onChange={handleAltTextChange}
                readOnly
              />
            </div>
            {assetType && (
            <div className="form-group formControlTextArea">
              <label htmlFor="date_of_publication" className="form-field-label">
                Date of Publication
              </label>
              {/* <input className="form-control" id="date_of_publication" name="date_of_publication" value={formData.date_of_publication} onChange={handleAltTextChange} />     */}

              <div className="datePick col-md-4 col-lg-3">
                <DatePicker
                  selected={
                    selectedDate ?? parseDate(imageData.publishing_date)
                  }
                  onChange={(date, _) => handleDateChange(date)}
                  // onBlur={handleDateBlur}
                  placeholderText="NA"
                  className="datePicker mb-2"
                  dateFormat="dd/MM/yyyy"
                  startDate={new Date(imageData.publishing_date)}
                  // endDate={endDate}
                  // isClearable
                  readOnly
                />
                {dateValidationError && (
                  <div className="error-message">{dateValidationError}</div>
                )}
              </div>
            </div>
            )}

            <button
              type="button"
              className="btn btn-primary btn-lg mt-4 save-button"
              onClick={handleSave}
              disabled={imageData.final_meta_data.Title === "" || imageData.final_meta_data.Description === ""}
            >
              {saving ? (
                <>
                  <i className="saveIcon"></i>
                  <span className="text">Saving</span>
                </>
              ) : saved ? (
                <span>Saved</span>
              ) : (
                <span>Save</span>
              )}
            </button>
          </div>
        </div>
        {/* Display other image details */}
      </div>

      {/* Modal for Enlarged Image */}
      <div
        className={`modal ${showModal ? "show" : ""}`}
        tabIndex={-1}
        role="dialog"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className="preview-image mb-3">
                <span>Image Preview</span>
                <span onClick={handleModalToggle}>
                  <i className="close-icon"></i>
                </span>
              </div>
              <div>
                <img
                  src={imageData.original_file_path}
                  alt={imageData.file_name}
                  className="img-fluid enlarged-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AltTextDetails
