import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import "./ForgetPassword.scss"
import axios from "axios"
import { ToastContainer, cssTransition, toast } from "react-toastify"
import LeftContainer from "../LeftContainer/LeftContainer"
interface forgetProps {
  setDefault: () => void
}

const ForgetPassword = (props:forgetProps) => {
  props.setDefault()
  const [username, setUsername] = useState<string>("")
  const [submitError, setSubmitError] = useState<number>(0)
  const [submitSuccess, setSubmitSuccess] = useState<number>(0)
  const apiBasePath = process.env.REACT_APP_API_URL
  const basePath = process.env.REACT_APP_BASE_PATH
  const navigate = useNavigate()

  const customId = "toast-id-email"
  const Enter = cssTransition({
    enter: "fade-in",
    exit: "fade-out",
    appendPosition: false,
    collapse: true,
    collapseDuration: 300
  })
  const errorNotificaion = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: customId,
      transition: Enter,
      containerId: "send_email"
    })
  }
  const CloseButton = ({ closeToast }: any) => (
    <div
      className="send_email-close-btn Login-error-close-btn"
      onClick={closeToast}
    ></div>
  )
  const CloseButtonSuccess = ({ closeToast }: any) => (
    <div className="Logout-success-close-btn" onClick={closeToast}></div>
  )
  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    const errorMsg = "The email field is required."
    if (username.trim() === "") {
      errorNotificaion(errorMsg)
      setSubmitError(1)
      setSubmitSuccess(0)
      
    }
    if (username !== "") {
      axios
        .post(`${apiBasePath}api/forgetPassword`, {
          email: username
        })
        .then((response) => {
          if (response.status === 200) {
            setSubmitSuccess(1)
            const successId = 'toast-sucess-msg'
            toast.success('We have sent a password recovery instructions mail.', {
              position: toast.POSITION.TOP_CENTER,
              // eslint-disable-next-line
              icon: false,
              toastId: successId,
              containerId: "send_email_success"
            })
            setSubmitError(0)
          }
        })
        .catch((error) => {
          const responseStatus = error.response.status
          const wrongMail = "Wrong Email address."
          const inValidMail = "The email field must be a valid email address."
          if (responseStatus === 401) {
            errorNotificaion(inValidMail)
          }
          if (responseStatus === 400) {
            errorNotificaion(wrongMail)
          }
          setSubmitError(1)
      setSubmitSuccess(0)

        })
    }
  }

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
    setSubmitError(0)
    setSubmitSuccess(0)
    toast.dismiss(customId)
    toast.dismiss('toast-sucess-msg')    
  }

  const backToLogin = () => {
    if (`${basePath}` !== "") {
      navigate(`${basePath}`)
    } else {
      navigate(`/`)
    }
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <LeftContainer />
        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-7 right-container">
          <div className="col-sm-12 col-xs-12 col-md-8 col-lg-7 col-xl-6 col-xxl-5 right-container__loginParent">
            <div className="right-container__loginForm">
              
              {submitError === 1 && <ToastContainer
                containerId={"send_email"}
                className="notification-container"
                toastClassName="notification-wrapper"
                bodyClassName="notification-body"
                closeButton={CloseButton}
                hideProgressBar={true}
                autoClose={false}
              />}
              {submitSuccess === 1 && <ToastContainer
                containerId={"send_email_success"}
                className="notification-container-success"
                toastClassName="notification-wrapper-success"
                bodyClassName="notification-body-success"
                closeButton={CloseButtonSuccess}
                hideProgressBar={true}
                autoClose={false}
              />}
              <div className="right-container__txtContainer mx-auto mb-3 mt-4 forgot-passowrd">
                <span className="right-container__signInTxt mb-1">
                  Forgot Password
                </span>
                <span className="right-container__subLine">
                  Enter your registered email address to get link for reset your
                  password
                </span>
              </div>
              <form id="emailForm" onSubmit={handleFormSubmit}>
                <div className="mt-5 mb-5 mx-auto right-container__emailField">
                  <input
                    className="form-control"
                    id="inputEmail"
                    placeholder="Email Address"
                    value={username}
                    onChange={handleUsernameChange}
                  />
                </div>
                <button className="right-container__submitBtn">
                  Send Link
                </button>
              </form>
              <button
                type="button"
                className="right-container__backToLogin-btn m-5"
                onClick={backToLogin}
              >
                Back to Login
              </button>
            </div>
            <div className="loginFooter">
              <div className="logoImg"></div>
              <div className="copyrightText">Copyright 2023 exacttag.ai. All Rights Reserved.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword
