import React, { useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import "./Upload.scss"
import axios, { CancelTokenSource } from "axios"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
let newFiles: any[] = []
let uploadProgressPercent = 0
interface uploadProps {
  randomKey: number
}
const Upload = (props: uploadProps) => {
  let imgLoaded = false;
  const navigate = useNavigate()
  const [uploadingStatus, setUploadingStatus] = useState(false)
  const [areAllElementsEmpty, setAreAllElementsEmpty] = useState(false)
  const [removedItem, setRemovedItem] = useState(0)
  const [uploadStart, setUploadStart] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [buttonLabel, setButtonLabel] = useState<string>("Submit")
  const [submitButtonState, setsubmitButtonState] = useState<string>("disabled")
  const [browseButtonState, setBrowseButtonState] = useState<string>("")

  const maxFileNotification = "Please select maximum 10 files."
  const fallbackImageUrl = require("../../assets/images/zip_thubneil.png")
  // const [thumbImage, setThumbImages] = useState<any[]>([])
  // New approach
  const [fileInfo, setFileInfo] = useState<any[]>([])
  const [userRole, setUserRole] = useState("Admin")
  const [isAdminUser, setIsAdminUser] = useState(false)
  const apiBasePath = process.env.REACT_APP_API_URL
  const basePath = process.env.REACT_APP_BASE_PATH
  useEffect(() => {
    if (removedItem === 1) {
      setRemovedItem(0)
      const check = fileInfo.every((file) => file === "")
      if (check) {
        setAreAllElementsEmpty(true)
        setFileInfo([])
      }
    }
  }, [removedItem])

  useEffect(() => {
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null)
    axios
        .get(
        `${apiBasePath}api/admin/checkUsersRoleData`,
        {
            headers: {
            Authorization: `Bearer ${userToken}`
            }
        }
        )
        .then((response) => {
          console.log(response.data)

            
        const data = response.data.data
        if (response.status === 200) {
          setUserRole(data.usertype)
          if (data.usertype && (data.usertype === "Admin" || data.usertype === "Super admin")) {
            setIsAdminUser(true)
          }
        }
        })
        .catch((error) => {
            console.log(error)
        })
  }, [])

  useEffect(() => {
    if (userRole === "Admin" || userRole === "Super admin") {
      navigate(`../${basePath}/Upload`)
      setIsAdminUser(true)
    } else {
      navigate(`../${basePath}/Dashboard`)
    }
  }, [userRole])

  const hasValidFile = () => {
    return fileInfo.some((file) => file.uploaded && file.isValid && file.progress === 100);
  }
  const areAllValidFilesUploaded = () => {
    return fileInfo.every((file) => file.uploaded && file.isValid && file.progress === 100);
  }
  const handleFileInfoChange = () => {
    console.log(hasValidFile(),'---', areAllValidFilesUploaded(), hasValidFile(), uploadStart)
    if (areAllValidFilesUploaded()){
      setsubmitButtonState("")
    }
    else if (hasValidFile() && uploadStart === 1) {
      setsubmitButtonState("")
    }
    if(fileInfo.length === 0 || hasValidFile() === false || areAllValidFilesUploaded()=== false && uploadStart === 0){
        setsubmitButtonState("disabled")
      }
  }


  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setUploadingStatus(false)
  }
  const fileInputRef = useRef<HTMLInputElement>(null)
  const submitBtnRef = useRef<HTMLButtonElement>(null)
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (event.dataTransfer.items !== null) {
      const files = Array.from(event.dataTransfer.items)
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile())
        let newUploadTimeout = null
      handleFiles(files)
      newFiles = [...files]
      if(newUploadTimeout){
        clearTimeout(newUploadTimeout)
      }
    }
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsModalOpen(false)
    if (event.target.files && event.target.files.length > 0) {
      const files: File[] = Array.from(event.target.files)
      let newUploadTimeout = null
      handleFiles(files)
      newFiles = [...files]
      if(newUploadTimeout){
        clearTimeout(newUploadTimeout)
      }
    }
  }
  const openFileInput = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
      setUploadingStatus(false)
    }
  }
  // Type check for files
  const isImageFile = (file: File): boolean => {
    const imageMimeTypes = ["image/jpeg", "image/png"]
    return imageMimeTypes.includes(file.type)
  }

  // For toast message when file slection exceeds 10
  const customId = "toast-id-upload"
  const errorNotificaion = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: customId,
      containerId: "Error"
    })
  }

  useEffect(()=>{
      handleFileInfoChange()
  }, [uploadStart])
  useEffect(() => {
    if (fileInfo.length > 0 && uploadingStatus) {
      handleUploadAll(fileInfo)
    }
  }, [uploadingStatus])

  const handleFiles = (files: any[]) => {
    const newFiles = Array.from(files)
    const zipFileTypes = [
      "application/zip",
      "application/x-zip-compressed",
      "application/octet-stream"
    ];
    if (newFiles.length > 10) {
      // Condition 1: Maximum 10 files can be selected
      // const errorMessage = "Please select maximum 10 files."
      errorNotificaion(maxFileNotification) // Function to display error message (toast)
      newFiles.splice(10) // Remove additional files beyond the limit
    }
    console.log('newFiles',newFiles);
    
    const updatedFileInfo = newFiles.map((file, i) => {    
      let fileInfo = {
        file: file,
        name: file.name,
        type: file.type,
        size: file.size,
        progress: 0,
        controller: new AbortController(),
        uploaded: false,
        removed: false,
        error: "",
        isValid: false,
        thumbImg: ""
      }
      const fileSize = Number((file.size / (1024 * 1024)).toFixed(2))
      fileInfo.type = file.type
      fileInfo.size = fileSize
      if (newFiles.length > 0) {
        fileInfo.isValid = true
        fileInfo.error = ""
      }
      if (
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/PNG" 
      ) {
        let img = new Image()
        img.src = URL.createObjectURL(file)
        img.onload = () => {
          if (img.width >=50 && img.height >= 50 && fileSize <= 5 && newFiles.length > 0) {
            fileInfo.isValid = true;
            fileInfo.error = "";
            
          } else {
            fileInfo.isValid = false;
            if (fileSize > 5 && img.width >=50 && img.height >= 50) {
              fileInfo.error = "File size exceeds the max limit of 5 MB for the image.";
            }
            if (fileSize <= 5 && img.width < 50 && img.height < 50) {
              fileInfo.error = "Image resolution should be at least 50x50 pixels.";
            }
          }
          setUploadingStatus(true)
        }
        if (
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png" ||
          file.type === "image/PNG" 
        ) {
          fileInfo.thumbImg = URL.createObjectURL(file)          
        } else {
          fileInfo.thumbImg = fallbackImageUrl
        }
      } else if (zipFileTypes.includes(file.type)) {
        const fileSize = Number((file.size / (1024 * 1024)).toFixed(2))
        fileInfo.thumbImg = fallbackImageUrl
        if (fileSize <= 5000) {
          fileInfo.error = ""
          fileInfo.isValid = true
        } else {
          fileInfo.isValid = false
          fileInfo.error =
            "File size exceeds the max limit of 5 GB for zip files."
        }
      } 
      else {
        fileInfo.thumbImg = fallbackImageUrl
        fileInfo.isValid = false
        fileInfo.error = "Please select a valid file type."
      }
      setUploadingStatus(true)
      return fileInfo
    })
    setFileInfo((prevFileInfo) => [...prevFileInfo, ...updatedFileInfo])
    setRemovedItem(0)
    setAreAllElementsEmpty(false)
    setsubmitButtonState("disabled")
    setUploadStart(0)
  }

  const handleUploadAll = async (files: any[]) => {
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      if (
        !file.uploaded &&
        file.progress < 1 &&
        !file.removed &&
        file.isValid
      ) {
        await handleFileUpload(file.file, index, file.controller)
      }
    }
  }

  const handleFileUpload = async (
    file: any,
    index: number,
    controller: any
  ) => {
    const userId = localStorage.getItem("user_id")
    const clientId = localStorage.getItem("client_id")
    const userToken = localStorage.getItem("user-token")
    try {
      if (userId && clientId && userToken) {
        const signal = controller.signal
        // const signal = file.controller.signal
        const formData = new FormData()
        // console.log(file.length, 'filesToDb---')

        // formData.append("file", file)
        formData.append("client_id", clientId)
        formData.append("user_id", userId)
        formData.append("files[]", file)
        // let uploadedFiles = 0;
        const response = await axios
          .post(
            `${apiBasePath}api/upload`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "multipart/form-data"
              },
              onUploadProgress: (progressEvent: any) => {
                const uploadProgressPercent = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                )
                if (uploadProgressPercent <= 98) {
                  setFileInfo((prevFileInfo: any) => {
                    const updatedFileInfo = [...prevFileInfo]
                    if(updatedFileInfo[index] !== "" && updatedFileInfo[index] !== undefined) {
                      updatedFileInfo[index].progress = uploadProgressPercent
                    }
                    return updatedFileInfo
                  })
                }
                setUploadStart(0)
              },
              signal: signal
            }
          )
          .then((response) => {
            if (response.data.status === 200) {
              
              setFileInfo((prevFileInfo) => {
                const updatedFileInfo = [...prevFileInfo]                
                if(updatedFileInfo[index] !== "" && updatedFileInfo[index] !== undefined) {
                  updatedFileInfo[index].uploaded = true
                  updatedFileInfo[index].progress = 100
                }
                return updatedFileInfo
              })
              setUploadStart(1)
            }
          })
      }
    } catch (error) {
      if (axios.isCancel(error) && fileInfo.length > 0) {        
        setFileInfo((prevFileInfo) => {
          const updatedFileInfo = [...prevFileInfo]
          if (updatedFileInfo[index] !== undefined){
            updatedFileInfo[index].uploaded = false
            updatedFileInfo[index].error = "Upload request is cancelled."
          }
          return updatedFileInfo
        })
      } else {
        console.log(error, '--');
        if (axios.AxiosError.ERR_NETWORK) {

          setFileInfo((prevFileInfo) => {
            const updatedFileInfo = [...prevFileInfo]
            if(updatedFileInfo[index] !== "" && updatedFileInfo[index] !== undefined) {
            updatedFileInfo[index].uploaded = false
            updatedFileInfo[index].error = "Uploading failed."
          }
            return updatedFileInfo
          })
        }
      }
    }
  }

  const handleCancelUpload = (index: number) => {
    setFileInfo((prevFileInfo) => {
      const updatedFileInfo = [...prevFileInfo]
      updatedFileInfo[index].progress = 0
      updatedFileInfo[index].uploaded = false
      updatedFileInfo[index].error = "Upload request has been cancelled."
      updatedFileInfo[index].controller.abort()
      return updatedFileInfo
    })
    if(uploadStart === 0) {
      setUploadStart(1)
    }else {
      setUploadStart(0)
    }
    // handleFileInfoChange()
  }
  const handleRemoveFile = (index: number) => {
    setRemovedItem(1)
    setUploadingStatus(false)
    setFileInfo((prevFileInfo) => {
      const updatedFileInfo = [...prevFileInfo]
      updatedFileInfo[index].uploaded = true
      updatedFileInfo[index].removed = true
      updatedFileInfo[index].error = ""
      updatedFileInfo.splice(index, 1, "")
      return updatedFileInfo
    })
    if(uploadStart === 0) {
      setUploadStart(1)
    }else {
      setUploadStart(0)
    }
  }
  const cancelAllUpload = () => {
    toast.dismiss(customId)
    fileInfo.forEach((fileInfoItem) => {
      if(fileInfoItem !== ""){
      fileInfoItem.controller.abort();
      fileInfoItem.progress = 0;
      fileInfoItem.uploaded = false;
      fileInfoItem.error = "Upload request is cancelled.";}
    });
    setAreAllElementsEmpty(true)
    setFileInfo([])
    setButtonLabel("Submit")
    setsubmitButtonState("disabled")
  }
  const submitFiles = () => {
    // console.log("call to db")
    if(submitBtnRef.current?.classList.contains('disabled')){
      return
    }
    const userId = localStorage.getItem("user_id")
    const clientId = localStorage.getItem("client_id")
    const userToken = localStorage.getItem("user-token")
    const formData = new FormData()
    if (userId && clientId && userToken) {
      setsubmitButtonState('disabled')
      setBrowseButtonState('disabled')
      setButtonLabel('Processing...')
      formData.append("client_id", clientId)
      formData.append("user_id", userId)

      // const filesToServer = []
      for (let index = 0; index < fileInfo.length; index++) {
        const file = fileInfo[index]
        // console.log(file, "-", file.file)
        if (
          file.uploaded &&
          file.progress === 100 &&
          !file.removed &&
          file.isValid
        ){
          formData.append("files[]", file.file)
        }
      }
        axios
        .post(
          `${apiBasePath}api/storeFiles`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (progressEvent: any) => {
              const submitProgress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              setButtonLabel('Processing...')
            },
          }
        )
        .then((response) => {
          // console.log(response);
          
          if (response.data.status === 200) {
            setsubmitButtonState('disabled')
            setBrowseButtonState('disabled')
            navigate(`../${basePath}/Dashboard`)
            setButtonLabel('Submit')
          }
        })
        .catch((error) => {
          setButtonLabel('Submit')
          console.log(error, 'some error');
          setsubmitButtonState('disabled')
          setBrowseButtonState("")
          errorNotificaion('Uploading failed')
        })
    }
  }
  return (
    <>
    {isAdminUser && (
      <div
        key={props.randomKey}
        className="container-fluid upload-container mb-3"
      >
        <div className={`diloag-backdrop ${isModalOpen ? "open" : ""}`}></div>
        <div className="row uploadImageContainer">
        <div className="col-xs-12 col-md-12 col-lg-10 col-xl-8 col-xxl-6 upload-container__header text-center mt-4">
          <strong>
            Automated
            <span className="alt-text-bg w-auto mx-1 px-2"> Meta Descriptions </span>
          </strong>
        </div>
        <div className="col-xs-12 col-md-12 col-lg-10 col-xl-8 col-xxl-6 description-text text-center mt-2">
          Effortlessly craft impactful meta descriptions from uploading your files
        </div>
        <div className="col-xs-12 col-md-12 col-lg-10 col-xl-7 col-xxl-6 browse-container container d-flex mt-3 flex-wrap p-4">
          <div
            className="p-4 row inner-container-parent mx-auto align-items-center file-upload"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div className="inner-container mx-auto">
              <div className="drag-instruction mb-2">
                Drag and Drop your image / file here to generate Meta description
              </div>
              <div className="or-txt mb-2">OR</div>
              <div className="upload-instruction mb-2">
                Upload your file by clicking the Browse button below
              </div>
              <button
                className={`browse-btn mb-2 btn btn-primary ${browseButtonState}`}
                onClick={openFileInput}
              >
                Browse
              </button>
              <input
                type="file"
                multiple
                accept=".jpeg, .png, .zip, .jpg"
                onChange={handleFileChange}
                hidden
                ref={fileInputRef}
              />
              <div className="supported-files-text mb-2">
                Supported files
                <span className="file-types"> jpeg, png, zip</span>
              </div>
              <div className="file-limit-text">
                Max size below <span className="file-types">5 MB</span> for
                image & <span className="file-types">5 GB</span> for files
              </div>
            </div>
          </div>

          {fileInfo.length > 0 && !areAllElementsEmpty && (
            <div className="upload-files-container mt-3">
              <div className="uploaded-files-txt mb-3">Uploaded Files:</div>
              {fileInfo.map((file, index) => (
                <div
                  key={`file_${index}`}
                  className={`file-status mb-2 ${file === "" && "hidden"}`}
                >
                  <div className="thumb-img-container ms-3 me-3">
                    <img
                      src={file.thumbImg}
                      alt="thumb-img"
                      className="thum-img"
                    />
                  </div>
                  <div className="file-item">
                    <div className="file-name mb-2 mt-3">{file.name}</div>
                    {file.error === "" && file.progress > 0 ? (
                      <>
                        <div className="progress">
                          <div
                            key={`${index}_progress-bar`}
                            className={`progress-bar ${
                              file.progress === 100 && "upload-success"
                            }`}
                            role="progressbar"
                            aria-valuenow={file.progress}
                            aria-valuemin={0}
                            aria-valuemax={100}
                            style={{ width: file.progress + "%" }}
                          ></div>
                        </div>
                        <div className="upload-percentage">
                          <div className="percent-val">{`${file.progress}%`}</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="error-msg mb-3">{file.error}</div>
                      </>
                    )}
                    {file.progress === 0 && file.error === "" && (
                      <div className="file-size mb-3">{file.size} MB</div>
                    )}
                  </div>
                  <div className="btn-container d-flex">
                    {file.progress > 0 &&
                    file.progress < 100 && file.error == "" &&
                    !file.uploaded ? (
                      <button
                        type="button"
                        className="cancel-btn status-btn"
                        onClick={() => handleCancelUpload(index)}
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="deleteBtn status-btn hidden"
                        id={`file--${index}`}
                        onClick={() => handleRemoveFile(index)}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              ))}

              <div className="uploadCancelBtn-container mt-5 mb-1">
                <button
                
                  className={`upload-cancel-btn btn btn-primary me-3 ${browseButtonState}`}
                  onClick={cancelAllUpload}
                >
                  Cancel
                </button>
                <button
                  className={`upload-file-btn btn btn-primary ${submitButtonState}`}
                  ref={submitBtnRef}
                  onClick={submitFiles}
                >
                  {buttonLabel}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      </div>
    )}
    </>
  )
}

export default Upload
