import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import "./Header.scss"
import axios from "axios"
import { toast, ToastContainer, cssTransition } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import e from "express"

interface HeaderProps {
  onLogout: () => void
  generateRandomKey: () => void
}
const Header = (props: HeaderProps) => {
  const navigate = useNavigate()
  const [userName, setUsername] = useState<string>("")
  const [emailId, setEmailId] = useState<string>("")
  const [isAdminLogin, setIsAdminLogin] = useState(false) // check Admin Login
  const apiBasePath = process.env.REACT_APP_API_URL
  const basePath = process.env.REACT_APP_BASE_PATH

  const logout = () => {
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null) {
      axios
        .get(
          `${apiBasePath}api/logout`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          }
        )
        .then((response) => {
          console.log(response)
          if (response.status === 200) {
            localStorage.clear()
            if (`${basePath}` !== "") {
              navigate(`${basePath}`)
            } else {
              navigate(`/`)
            }
            props.onLogout()
          }
        })
        .catch((error) => {
          console.log(error, 'error')
        })
      }else {        
        props.onLogout()
        localStorage.clear()
        if (`${basePath}` !== "") {
          navigate(`${basePath}`)
        } else {
          navigate(`/`)
        }
      }
        
  }
  const dashboard = () => {
    props.generateRandomKey()
    navigate(`../${basePath}/Dashboard`)
  }
  
  const upload = () => {
    toast.dismiss()
    props.generateRandomKey()
    navigate(`../${basePath}/Upload`)
  }
  const changePassword = () => {
    navigate(`../${basePath}/ChangePassword`)
  }
  useEffect(() => {
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null)
      axios
        .get(
          `${apiBasePath}api/getUser`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          }
        )
        .then((response) => {
          const data = response.data.data
          // console.log(data)
          if (response.status === 200) {
            
            setUsername(data.name)
            setEmailId(data.email)
            localStorage.setItem("user_id", data.user_id)
            localStorage.setItem("client_id", data.client_id)
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            console.log(error, "ERROR")
            setUsername("Unauthorized Access")
            setEmailId("Unauthorized Access")
          }
        })
  }, [])
  const customId = "toast-id-logOut"
  const errorNotificaion = (errorMessage:string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
      // eslint-disable-next-line
      icon: false,
      toastId: customId,
      containerId: 'Logout-success'
    })
  }

  useEffect(() => {
    isAdmin()
}, [])

const isAdmin = async () => {
    const userToken = localStorage.getItem("user-token")
    if (userToken !== null)
    axios
        .get(
        `${apiBasePath}api/admin/checkUsersRoleData`,
        {
            headers: {
            Authorization: `Bearer ${userToken}`
            }
        }
        )
        .then((response) => {
          console.log(response.data)

            
        const data = response.data.data
        if (response.status === 200) {
            if (data.usertype === 'Admin' || data.usertype === 'Super admin') {
                setIsAdminLogin(true)
            }
        }
        })
        .catch((error) => {
            console.log(error)
        })
}

  return (
    <header>
      <nav className="navbar navbar-expand-lg header-navbar mx-auto p-3">
        <a className="navbar-brand home-page me-5" onClick={dashboard} >
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-between"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <a className="nav-link" onClick={dashboard} >
                Dashboard
              </a>
            </li>
            {isAdminLogin && (
            <li className="nav-item">
              <a className="nav-link" onClick={upload} >
                Upload
              </a>
            </li>
            )}
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="profile">
                  <span className="profile-name user-creds">{userName}</span>
                  <span className="email-id user-creds">{emailId}</span>
                </div>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbarDropdown"
              >
                <a
                  className="dropdown-item change-password"
                  onClick={changePassword}
                >
                  <img
                    src={require("../../assets/images/change_password.png")}
                    alt="change password"
                  />
                  <span className="change-password__text">Change Password</span>
                </a>
                <a className="dropdown-item logout" onClick={logout} >
                  <img
                    src={require("../../assets/images/logout.png")}
                    alt="change password"
                  />
                  <span className="logout__text">Logout</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  )
}
export default Header
